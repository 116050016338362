import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const BottomNavigation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <nav className="fixed bottom-0 w-full bg-white border-t pb-5">
      <div className="flex justify-around py-2">
        <button 
          onClick={() => navigate('/')}
          className={`flex flex-col items-center ${location.pathname === '/' ? 'text-blue-500' : 'text-gray-500'}`}
        >
          <span>🏠</span>
          <span className="text-xs">home</span>
        </button>
        <button 
          onClick={() => navigate('/chats')}
          className={`flex flex-col items-center ${location.pathname === '/chats' ? 'text-blue-500' : 'text-gray-500'}`}
        >
          <span>💬</span>
          <span className="text-xs">chats</span>
        </button>
        <button 
          onClick={() => navigate('/profile')}
          className={`flex flex-col items-center ${location.pathname === '/profile' ? 'text-blue-500' : 'text-gray-500'}`}
        >
          <span>👤</span>
          <span className="text-xs">profile</span>
        </button>
      </div>
    </nav>
  );
};

export default BottomNavigation;

import React from 'react';
import { Session } from '@supabase/supabase-js';
import { Profile } from '../services/api';

interface ProfileIndicatorProps {
  session: Session;
  profile: Profile | null;
}

const ProfileIndicator: React.FC<ProfileIndicatorProps> = ({ session, profile }) => {
  if (!session) return null;

  // Determine initials
  let initials = '';
  if (profile && profile.first_name && profile.last_name) {
    initials = `${profile.first_name.charAt(0).toUpperCase()}${profile.last_name.charAt(0).toUpperCase()}`;
  } else if (session.user.email) {
    initials = session.user.email.charAt(0).toUpperCase();
  }

  return (
    <div className="flex items-center">
      <div 
        className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer hover:opacity-80 overflow-hidden"
      >
        {session.user.user_metadata.avatar_url ? (
          <img 
            src={session.user.user_metadata.avatar_url} 
            alt={session.user.email}
            className="w-full h-full object-cover"
          />
        ) : (
          <span className="text-sm font-medium">{initials}</span>
        )}
      </div>
    </div>
  );
};

export default ProfileIndicator;

// AppHeader.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/png/favicon-8.png';
import ProfileIndicator from './ProfileIndicator';
import { Session } from '@supabase/supabase-js';
import { Profile } from '../services/api';

// Add props interface
interface AppHeaderProps {
  session: Session | null;
  profile: Profile | null;
}

const AppHeader: React.FC<AppHeaderProps> = ({ session, profile }) => {
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate('/profile');
  };

  return (
    <header className="sticky top-0 bg-white border-b">
      <div className="flex items-center justify-between px-4 py-2">
        <img src={logo} alt="hotlines.ai" className="h-8 w-8" onClick={() => navigate('/')} />
        <div className="flex-1 mx-4">
          <div className="bg-gray-100 rounded-full px-4 py-2">
            {/* TODO: Change behavior on click for search experience */}
            <input 
              type="text" 
              placeholder="Search for any business"
              className="bg-transparent w-full outline-none"
            />
          </div>
        </div>
        <div onClick={handleProfileClick}>
          {session ? (
            <ProfileIndicator 
              session={session} 
              profile={profile}
            />
          ) : (
            <div className="w-8 h-8 rounded-full bg-gray-200"></div>
          )}
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
import React, { useEffect, useState } from 'react';
import { getChats, Chat } from '../services/api';
import { Profile } from '../services/api';
import { Session } from '@supabase/supabase-js';
import AppHeader from './AppHeader';
import BottomNavigation from './BottomNavigation';
import PageContainer from './PageContainer';

interface ChatListProps {
    profile: Profile | null;
    session: Session;
}

const ChatList: React.FC<ChatListProps> = ({ profile, session }) => {
    const [chats, setChats] = useState<Chat[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchChats = async () => {
            try {
                setLoading(true);
                const response = await getChats(session);
                setChats(response.data);
            } catch (error) {
                console.error('Error fetching chats:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchChats();
    }, [session]);

    if (loading) return <div>Loading...</div>;

    return (
        <PageContainer>
            <AppHeader session={session} profile={profile} />
            
            <div className="flex-grow overflow-y-auto pb-16">
                {chats.map(chat => (
                    <div 
                        key={chat.id} 
                        className="border-b border-gray-100 px-4 py-4"
                    >
                        <div className="flex justify-between items-start">
                            <h2 className="text-lg font-semibold text-gray-900">
                                {chat.business.username}
                            </h2>
                            <span className="text-sm text-gray-400">
                                {/* Replace with actual timestamp logic */}
                                1h ago
                            </span>
                        </div>
                        <p className="text-gray-500 mt-1 line-clamp-1">
                            {chat.lastMessage}
                        </p>
                    </div>
                ))}
            </div>

            <BottomNavigation />
        </PageContainer>
    );
};

export default ChatList;
import React from 'react';
import { Session } from '@supabase/supabase-js';
import { Profile } from '../services/api';
import AppHeader from './AppHeader';
import BottomNav from './BottomNavigation';
import PageContainer from './PageContainer';

interface AppFeedProps {
  session: Session;
  profile: Profile | null;
}

// TODO: Rework this interface with post elements
interface Post {
  id: number;
  business: {
    name: string;
    username: string;
    avatar: string;
  };
  content: string;
  hashtags: string[];
  likes: number;
  comments: number;
}

// TODO: Pull these posts from server
const SAMPLE_POSTS: Post[] = [
  {
    id: 1,
    business: {
      name: "Indigo Airlines",
      username: "@goindigo",
      avatar: "https://seeklogo.com/images/I/indigo-logo-EDBB4B3C09-seeklogo.com.png"
    },
    content: "Exciting news! We've just added 5 new routes connecting Mumbai to South East Asia. Book now for a special discount!",
    hashtags: ["Thailand", "Indonesia"],
    likes: 245,
    comments: 5
  },
  {
    id: 2,
    business: {
      name: "Copper Chimney",
      username: "@cc-resto",
      avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3ymX8eaUXjZ5U4aLLZdMetgPt9iD_V0jvzw&s"
    },
    content: "Come try out all new Diwali menu as an unlimited thali on Sunday Oct 30th",
    hashtags: ["Brunch", "IndianFood", "Diwali"],
    likes: 245,
    comments: 5
  }
];

const AppFeed: React.FC<AppFeedProps> = ({ session, profile }) => {
    return (
      <PageContainer>
        <AppHeader session={session} profile={profile} />
        
        <main className="flex-1 px-4 pb-16 pt-3"> {/* Applies padding around feed */}
          {SAMPLE_POSTS.map((post) => (
            // TODO: Pull this post object into a common component
            <div key={post.id} className="bg-white mb-4 rounded-2xl shadow-sm overflow-hidden">
              {/* Business Header */}
              {/* TODO: On click of business header, open business profile */}
              <div className="flex items-center p-4">
                <div className="w-12 h-12 rounded-full bg-gray-200 overflow-hidden shadow-sm border border-gray-200">
                  <img 
                    src={post.business.avatar} 
                    alt="" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="ml-3">
                  <div className="font-semibold text-blue-600">{post.business.name}</div>
                  <div className="text-sm text-gray-500">{post.business.username}</div>
                </div>
              </div>
  
              {/* Post Content */}
              <div className="px-4 pb-3">
                <div className="text-[20px] leading-[1.3] mb-2">
                  {post.content}
                </div>
  
                {/* Hashtags */}
                <div className="mb-4">
                  {post.hashtags.map((tag) => (
                    <span key={tag} className="text-gray-500 mr-1">#{tag}</span>
                  ))}
                </div>
  
                {/* Engagement Metrics */}
                <div className="flex items-center text-gray-500">
                  <div className="flex items-center mr-8">
                    <span className="mr-2">❤️</span>
                    <span className="text-[15px]">{post.likes}</span>
                  </div>
                  <div className="flex items-center mr-8">
                    <span className="mr-2">💬</span>
                    <span className="text-[15px]">{post.comments}</span>
                  </div>
                  <div className="flex items-center">
                    <span className="mr-2">↗️</span>
                    <span className="text-[15px]">share</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </main>
  
        <BottomNav />
      </PageContainer>
    );
  };

export default AppFeed;
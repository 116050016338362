import React, { ReactNode } from 'react';

interface PageContainerProps {
  children: ReactNode;
  className?: string;
}

const PageContainer: React.FC<PageContainerProps> = ({ children, className = '' }) => {
  return (
    <div 
      className={`flex flex-col min-h-screen ${className}`} 
      style={{ 
        background: 'linear-gradient(to bottom, #EDF5FE, #F4F2FB, #FBF0F7)' 
      }}
    >
      {children}
    </div>
  );
};

export default PageContainer;
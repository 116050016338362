import React from 'react';
import { Session } from '@supabase/supabase-js';
import { supabase } from '../lib/supabaseClient';
import { Profile } from '../services/api';
import BottomNavigation from './BottomNavigation';
import AppHeader from './AppHeader';
import PageContainer from './PageContainer';

interface ProfilePageProps {
  session: Session | null;
  profile: Profile | null;
  onSignOut: () => void;
}

const ProfilePage: React.FC<ProfilePageProps> = ({ session, profile, onSignOut }) => {

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      onSignOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (!session || !profile) {
    return <div>Please log in</div>;
  }

  return (
    <PageContainer>
      <AppHeader session={session} profile={profile} />

      <div className="flex-grow p-4 pt-0">
        <div className="bg-white rounded-lg shadow-md p-6 space-y-4 mt-4">
          <div>
            <label className="block text-gray-600 font-semibold mb-2">Name</label>
            <p className="text-gray-900 text-lg">{profile.first_name || 'Not set'}</p>
          </div>
          
          <div>
            <label className="block text-gray-600 font-semibold mb-2">Email</label>
            <p className="text-gray-900 text-lg">{session.user.email}</p>
          </div>
          
          <button 
            onClick={handleSignOut}
            className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition duration-300 mt-4"
          >
            Sign Out
          </button>
        </div>
      </div>
      
      <BottomNavigation />
    </PageContainer>
  );
};

export default ProfilePage;